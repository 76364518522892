import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'cjp-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
})
export class SelectFieldComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public formFieldName: string;

  @Input() parent: FormGroup;

  @Input()
  readonly: boolean;

  required: boolean;

  @Input()
  public options: any[];

  @Input()
  public valueSelector: (item) => any;

  @Input()
  formatter: (item) => string;

  constructor() {}

  ngOnInit() {
    if (this.parent && this.parent.get(this.formFieldName)) {
      const validator = this.parent.get(this.formFieldName).validator;

      if (validator) {
        this.required = validator({} as AbstractControl).required;
      }
    }
  }
}
