// NGRX
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, createReducer, MetaReducer, on } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { setVisibleFunds } from '../../services/fund/fund.actions';

import { environment } from '../../../environments/environment';

// tslint:disable-next-line:no-empty-interface
export interface AppState { }


const _fundReducer = createReducer(
    null,
    on(setVisibleFunds, (state, funds) => funds.funds)
);

export function fundReducer(state, action) {
    return _fundReducer(state, action);
}

export const reducers: ActionReducerMap<AppState> = { 
    router: routerReducer,
    funds: fundReducer,
 };

export const metaReducers: Array<MetaReducer<AppState>> = !environment.production ? [storeFreeze] : [];
