import { DefaultAuthGuard } from './services/auth.guard';
import { DashboardComponent } from './views/pages/dashboard/dashboard.component';
import { LoginComponent } from './views/pages/auth/login/login.component';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './layouts/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('../app/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [DefaultAuthGuard],
    children: [
      {
        path: 'job',
        loadChildren: () =>
          import('../app/pages/job/job.module').then((m) => m.JobModule),
      },
      {
        path: 'steward-card',
        loadChildren: () =>
          import('../app/pages/steward-card/steward-card.module').then(
            (m) => m.StewardCardModule
          ),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('../app/pages/report/report.module').then(
            (m) => m.ReportModule
          ),
      },
      {
        path: 'employer',
        loadChildren: () =>
          import('../app/pages/employer/employer.module').then(
            (m) => m.EmployerModule
          ),
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'job', pathMatch: 'full' },
      { path: '**', redirectTo: 'job', pathMatch: 'full' },

      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc:
            "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
