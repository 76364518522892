import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cjp-multi-option-button',
  templateUrl: './multi-option-button.component.html',
  styleUrls: ['./multi-option-button.component.scss']
})
export class MultiOptionButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
