// Angular
import { Component } from '@angular/core';
import { LayoutConfigService, ToggleOptions } from '../../../../core/_base/layout';
import { HtmlClassService } from '../../../../layouts/html-class.service';

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  headerLogo: string;
  headerStickyLogo: string;

  toggleOptions: ToggleOptions = {
    target: 'body',
    targetState: 'kt-aside--minimize',
    togglerState: 'kt-aside__brand-aside-toggler--active',
  };

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param htmlClassService: HtmlClassService
   */
  constructor(private layoutConfigService: LayoutConfigService, public htmlClassService: HtmlClassService) {}
}
