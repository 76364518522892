import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app/core/reducers';
import { environment } from '../../../environments/environment';
import { setVisibleFunds } from './fund.actions';
import { Fund } from './fund.model';

@Injectable({
  providedIn: 'root'
})
export class FundService {

  private _apiHost: string;

  constructor(private httpClient: HttpClient, private store: Store<AppState>) {
    this._apiHost = environment.apiPath;
  }

  getMyFunds() {
    return this.httpClient.get<any[]>(`${this._apiHost}/api/funds/me`);
  }

  loadMyFunds() {
    this.getMyFunds().subscribe((funds: Fund[]) => {
      this.store.dispatch(setVisibleFunds({ funds: funds }));
    });
  }
}
