import { MsalService } from '@azure/msal-angular';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class DefaultAuthGuard implements CanActivate {
  constructor(private msalService: MsalService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const access = this.msalService.getAccount();

    if (!access) {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
    }

    return !!access;
  }
}
