import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SideBarSectionConfig } from './side-bar-section/side-bar-link.model';

@Component({
  selector: 'cjp-base-side-bar',
  templateUrl: './base-side-bar.component.html',
  styleUrls: ['./base-side-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseSideBarComponent implements OnInit {
  sectionConfigs: Array<SideBarSectionConfig>;

  constructor() {
    this.sectionConfigs = [
      {
        title: '',
        links: [{ title: 'Home', icon: 'fa-home', url: '/' }],
      },
      {
        title: 'Job Management',
        links: [
          {
            title: 'View Jobs',
            icon: 'fa-plus-square',
            url: '/job',
          },
          {
            title: 'Create Job Start',
            icon: 'fa-plus-square',
            url: '/job/create',
          },
        ],
      },
      {
        title: 'Job Starts',
        links: [
          {
            title: 'View Steward Cards',
            icon: 'fa-th-list',
            url: '/steward-card',
          },
        ],
      },
      {
        title: 'Employers',
        links: [
          {
            title: 'Add Employer',
            icon: 'fa-user-plus',
            url: '/employer',
          },
        ],
      },
      {
        title: 'Reports',
        links: [
          { title: 'Power BI Reports', icon: 'fa-chart-pie', url: '/report' },
        ],
      },
    ] as Array<SideBarSectionConfig>;
  }

  ngOnInit() {}
}
