import { JobNote } from '../job/job-note.model';
import { Job, JobSearchParams } from '../job/job.model';
import { JobAssignment } from '../job/job-asssignment.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Zone } from './zone.model';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  private apiPath = environment.apiPath;
  constructor(private client: HttpClient) {
  }

  getZones(zip: string, fundId: string) {
    return this.client.get(`${this.apiPath}/api/zones/search`, {
        params: {
          zip,
          fundId
        }
      });
  }

  update(zone: Zone) {
    return this.client.patch(`${this.apiPath}/api/zones/${zone.id}`, zone);
  }

  create(zone: Zone) {
    return this.client.post(`${this.apiPath}/api/zones`, zone);
  }
}
