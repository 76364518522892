export class SideBarSectionConfig {
  title: string;
  links: Array<SideBarLink>;
}

export class SideBarLink {
  title: string;
  url: string;
  icon: string;
  isActive: boolean;
}
