import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Client } from '@microsoft/microsoft-graph-client';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  // Have to be logged in through msal to work
  constructor(private msalService: MsalService) {}

  async getMemberGroups() {
    const token = await this.msalService.acquireTokenSilent({
      scopes: ['user.read'],
      redirectUri: `${window.location.origin}`,
    });

    const graphClient = Client.init({
      authProvider: async (done) => {
        // Get the token from the auth service
        done(null, token.accessToken);
      },
    });

    const response = await graphClient.api('/me/memberOf').get();

    return response.value.map((group) => {
      return group.displayName;
    });
  }

  async getUsers() {
    const token = await this.msalService.acquireTokenSilent({
      scopes: ['user.read'],
      redirectUri: `${window.location.origin}`,
    });

    const graphClient = Client.init({
      authProvider: async (done) => {
        // Get the token from the auth service
        done(null, token.accessToken);
      },
    });

    const response = await graphClient.api('/users').get();

    return response.value;
  }
}
