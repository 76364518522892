import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SubHeaderLink, SubheaderNotification } from './../../../../../core/_base/layout/services/subheader.service';
import { ChangeDetectorRef } from '@angular/core';
// Angular
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';





@Component({
  selector: 'kt-subheader2',
  templateUrl: './subheader2.component.html',
  styleUrls: ['./subheader2.component.scss'],
})
export class Subheader2Component implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  @Input() fluid: boolean;
  @Input() clear: boolean;
  today: number = Date.now();
  title = '';
  desc = '';
  breadcrumbs: Breadcrumb[] = [];
  link: SubHeaderLink;
  notification: SubheaderNotification;
  public url: string = this.router.url;
  // Private properties
  private subscriptions: Subscription[] = [];
  svgImage: string;


  /**
   * Component constructor
   *
   * @param subheaderService: SubheaderService
   */
  constructor(
    public subheaderService: SubheaderService,
    private changeRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.changeSvg("default");
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.url = this.router.url;
    this.changeSvg("default");
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        console.log(this.url);
      }
    });
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(

      this.subheaderService.title$.subscribe((bt) => {
        // breadcrumbs title sometimes can be undefined
        if (bt) {
          Promise.resolve(null).then(() => {
            this.title = bt.title;
            this.desc = bt.desc;
            this.changeRef.detectChanges();
          });
        }
      })
    );
    this.subscriptions.push(
      this.subheaderService.breadcrumbs$.subscribe((bc) => {
        Promise.resolve(null).then(() => {
          this.breadcrumbs = bc;
          this.changeRef.detectChanges();
        });
      })
    );

    this.subscriptions.push(
      this.subheaderService.link$.subscribe((link) => {
        Promise.resolve(null).then(() => {
          this.link = link;
          this.changeRef.detectChanges();
        });
      })
    );

    this.subscriptions.push(
      this.subheaderService.notification$.subscribe((notification) => {
        Promise.resolve(null).then(() => {
          // notification can be null and/or not exist
            this.notification = notification;
            this.changeRef.detectChanges();
        });
      })
    )

    // job = search(this.dataSource, this.link.link);
    this.router.events.subscribe(() => {
      this.link = null;
      this.notification = null;
      this.changeRef.detectChanges();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  goTo(urlFragments: any) {
    console.log(this.url);
    this.router.navigate(urlFragments);
    console.log(this.url);
  }



  getSvgFromString(state: string): string {
    if (state == "hover") {
      return "hover";
    } else if (state == "down") {
      return "down";
    } else {
      return "default";
    }
  }

  changeSvg(state: string) {
    this.svgImage = this.getSvgFromString(state);
  }


  
}
