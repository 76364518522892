import { JobNote } from './../../../../services/job/job-note.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { JobService } from './../../../../pages/job/job.service';
// Angular
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
// Layout
import { OffcanvasOptions } from '../../../../core/_base/layout';

@Component({
  selector: 'kt-quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss'],
})
export class QuickPanelComponent implements OnInit {
  // Public properties
  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'kt-quick-panel',
    closeBy: 'kt_quick_panel_close_btn',
    toggleBy: 'kt_quick_panel_toggler_btn',
  };
  noteForm: FormGroup;
  notes: any[];
  log: any[];
  currentJobId: string;

  constructor(
    private jobService: JobService,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private changeRef: ChangeDetectorRef
  ) {
    this.noteForm = builder.group({
      note: [''],
    });

    this.jobService.currentNotes$.subscribe((notes) => {
      this.notes = notes;
    });

    this.jobService.currentLog$.subscribe((log) => {
      this.log = log;
    });
  }

  ngOnInit() {}

  addNote() {
    let formValues = this.noteForm.getRawValue();

    let note = {
      jobId: null,
      created: new Date(),
      createdBy: '',
      updated: new Date(),
      updatedBy: '',
    } as JobNote;
    this.jobService.createJobNote({ ...note, ...formValues }).subscribe(() => {
      this.jobService.getJobNotesById().subscribe((notes: any[]) => {
        this.jobService.currentNotes$.next(notes);
      });
    });
  }
}
