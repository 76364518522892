import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SideBarService {
  setActiveLink$: BehaviorSubject<string> = new BehaviorSubject<string>('home');

  constructor() {}

  setActiveLink(title: string) {
    this.setActiveLink$.next(title);
  }
}
