import { currentAuthToken } from './../_selectors/auth.selectors';
import { MsalService } from '@azure/msal-angular';
// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, take, withLatestFrom } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import {
  AuthActionTypes,
  Login,
  Logout,
  Register,
  UserLoaded,
  UserRequested,
} from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { OAuthService } from 'angular-oauth2-oidc';
import { User } from '../_models/user.model';
import { Client } from '@microsoft/microsoft-graph-client';
import { GraphService } from './../../../services/graph/graph.service';

@Injectable()
export class AuthEffects {
  @Effect({ dispatch: false })
  login$ = this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    tap((action) => {
      localStorage.setItem(environment.authTokenKey, action.payload.authToken);
      this.store.dispatch(new UserRequested());
    })
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {
      localStorage.clear();
      this.msalService.logout();
      this.router.navigate(['login']);
    })
  );

  @Effect({ dispatch: false })
  register$ = this.actions$.pipe(
    ofType<Register>(AuthActionTypes.Register),
    tap((action) => {
      localStorage.setItem(environment.authTokenKey, action.payload.authToken);
    })
  );

  @Effect({ dispatch: false })
  loadUser$ = this.actions$.pipe(
    ofType<UserRequested>(AuthActionTypes.UserRequested),
    withLatestFrom(this.store.pipe(select(isUserLoaded))),
    filter(([action, _isUserLoaded]) => !_isUserLoaded),
    tap(([action, _user]) => {
      if (_user) {
        this.store.dispatch(new UserLoaded({ user: _user }));
      } else {
        const account = this.msalService.getAccount();
        const authToken$ = this.store.pipe(take(1), select(currentAuthToken));

        authToken$.subscribe(async (authToken) => {
          const memberGroups = await this.graphService.getMemberGroups();

          const newUser = {
            username: account.userName,
            fullname: account.name,
            email: account.userName,
            rawAccount: account,
            memberOf: memberGroups || [],
            accessToken: authToken,
          } as User;

          this.store.dispatch(new UserLoaded({ user: newUser }));
        });
      }
    })
  );

  @Effect()
  init$: Observable<Action> = defer(async () => {
    const userToken = localStorage.getItem(environment.authTokenKey);
    let observableResult = { type: 'NO_ACTION' };
    if (userToken) {
      observableResult = new Login({ authToken: userToken });
    }
    return observableResult;
  });

  private returnUrl: string;

  constructor(
    private actions$: Actions,
    private router: Router,
    private msalService: MsalService,
    private oAuthService: OAuthService,
    private graphService: GraphService,
    private store: Store<AppState>
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
      }
    });
  }
}
