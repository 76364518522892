import { currentAuthToken } from './../../core/auth/_selectors/auth.selectors';
import { AppState } from './../../core/reducers/index';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, take, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

@Injectable()
export class DefaultOAuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>, private router: Router) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();

    let token = '';
    this.store
      .pipe(take(1), select(currentAuthToken))
      .subscribe((s) => (token = s));

    const header = `Bearer ${token}`;
    const headers = req.headers.set('Authorization', header);
    req = req.clone({ headers });

    return next.handle(req).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            this.router.navigate(['login'], {
              queryParams: { returnUrl: document.location.pathname },
            });
          }
        }
      )
    );
  }
}
