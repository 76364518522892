import { JobNote } from './../../services/job/job-note.model';
import { Job, JobSearchParams } from './../../services/job/job.model';
import { JobAssignment } from '../../services/job/job-asssignment.model';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { WorkDay } from '../../services/job/workday.model';
import { Timesheet } from '../../services/job/timesheet.model';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  private apiPath = environment.apiPath;
  currentNotes$ = new BehaviorSubject<JobNote[]>([] as Array<JobNote>);
  currentLog$ = new BehaviorSubject<any[]>([] as Array<any>);
  currentJobId: string;

  constructor(private client: HttpClient) {
  }

  getJobs(page, limit = 0) {
    return this.client.get(`${this.apiPath}/api/jobs`);
  }

  search({ searchText, jobStatus, fundId, trade, onlyPinpoint, pageIndex, pageSize }: JobSearchParams) {
    return this.client.get(`${this.apiPath}/api/jobs/search`, {
      params: {
        searchTerm: searchText, // FIXME: inconsistent naming btw app and api
        jobStatus,
        fundId,
        trade,
        onlyPinpoint: onlyPinpoint.toString(),
        page: pageIndex.toString(),
        limit: pageSize.toString()
      }
    });
  }

  update(job: Job) {
    return this.client.patch(`${this.apiPath}/api/jobs/${job.id}`, job);
  }

  create(job: Job) {
    return this.client.post(`${this.apiPath}/api/jobs`, {
      job: job,
      isAdminCreated: true
    });
  }

  getJobById(id: string) {
    return this.client.get(`${this.apiPath}/api/jobs/${id}`).pipe(
      tap((job: Job) => {
        this.currentJobId = id;
        this.getJobNotesById().subscribe((notes: JobNote[]) => {
          this.currentNotes$.next(notes);
        });

        this.getLogByJobId().subscribe((notes: JobNote[]) => {
          this.currentLog$.next(notes);
        });
      })
    );
  }

  createJobNote(jobNote: JobNote) {
    jobNote.jobId = this.currentJobId;
    return this.client.post(`${this.apiPath}/api/jobs/${this.currentJobId}/notes`, jobNote);
  }

  getJobNotesById() {
    return this.client.get(`${this.apiPath}/api/jobs/${this.currentJobId}/notes`);
  }

  getLogByJobId() {
    return this.client.get(`${this.apiPath}/api/jobs/${this.currentJobId}/log`);
  }

  getJobsByUbc(search: string) {
    return this.client.get(`${this.apiPath}/api/jobs/${search}`);
  }

  getTimesheets(jobId: string) {
    return this.client.get(`${this.apiPath}/api/jobs/${jobId}/timesheets`);
  }

  getTimesheet(jobId: string, timesheetId: number): Observable<Timesheet> {
    return this.client.get<Timesheet>(`${this.apiPath}/api/jobs/${jobId}/timesheets/${timesheetId}`);
  }

  createWorkDay(jobId: string, timesheetId: number, workDay: WorkDay) {
    return this.client.post(`${this.apiPath}/api/jobs/${jobId}/timesheets/${timesheetId}/workdays`, workDay);
  }

  updateWorkDay(jobId: string, timesheetId: number, workDay: WorkDay): Observable<WorkDay> {
    return this.client.put<WorkDay>(
      `${this.apiPath}/api/jobs/${jobId}/timesheets/${timesheetId}/workdays/${workDay.id}`,
      workDay
    );
  }

  getJobAssigments(jobId: string): Observable<Array<JobAssignment>> {
    return this.client.get<Array<JobAssignment>>(`${this.apiPath}/api/jobs/${jobId}/assignments`);
  }
}
