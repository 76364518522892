import { SideBarService } from '../side-bar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SideBarSectionConfig } from './side-bar-link.model';

@Component({
  selector: 'cjp-side-bar-section',
  templateUrl: './side-bar-section.component.html',
  styleUrls: ['./side-bar-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideBarSectionComponent implements OnInit {
  @Input()
  config: SideBarSectionConfig;

  constructor(private sideBarService: SideBarService) {}

  ngOnInit() {
    this.sideBarService.setActiveLink$.subscribe((linkName) => {
      this.config.links.forEach((link) => {
        if (link.title.toLowerCase() === linkName.toLowerCase()) {
          link.isActive = true;
        } else {
          link.isActive = false;
        }
      });
    });
  }
}
