import { User } from './../../../core/auth/_models/user.model';
import { MsalToken, AccessData, MsalUser } from './../../../services/user/msal.model';
import { AppState } from './../../../core/reducers/index';
import { Login, UserLoaded } from './../../../core/auth/_actions/auth.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { LayoutConfigService } from './../../../core/_base/layout/services/layout-config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import * as objectPath from 'object-path';
import { Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationParameters } from 'msal';
import { Client } from '@microsoft/microsoft-graph-client';
import { FundService } from '../../../services/fund/fund.service';

@Component({
  selector: 'cjp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loaderLogo: string;
  errorMessage: string;
  graphClient: Client;
  returnUrl: string;

  constructor(
    private builder: FormBuilder,
    private oauthService: OAuthService,
    private store: Store<AppState>,
    private layoutConfigService: LayoutConfigService,
    private modalService: NgbModal,
    private broadcastService: BroadcastService,
    private fundService: FundService,
    private msalService: MsalService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.loginForm = builder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    const loaderConfig = layoutConfigService.getConfig('loader');
    this.loaderLogo = objectPath.get(loaderConfig, 'logo');
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.broadcastService.subscribe('msal:loginFailure', (payload) => {});
    this.broadcastService.subscribe('msal:loginSuccess', async (payload) => {
      this.store.dispatch(new Login({ authToken: payload.idToken['rawIdToken'] }));
      
      if (!!this.returnUrl) {
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.router.navigate(['/job']);
      }
    });
  }

  open(content) {
    this.modalService.open(content).result;
  }

  login() {
    this.msalService.loginPopup({
      scopes: ['user.read User.ReadBasic.All Directory.Read.All'],
      redirectUri: `${window.location.origin}`,
    } as AuthenticationParameters);
  }
}
