import { Action, createAction, props } from '@ngrx/store';
import { Fund } from './fund.model';

export enum FundActionTypes {
  SetFunds = 'Set Funds',
}

export const setVisibleFunds = createAction(
  FundActionTypes.SetFunds,
  props<{ funds: Fund[] }>()
);
